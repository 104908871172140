import logo from "./logo.png"
import img_1 from "./img-1.jpg"
import img_2 from "./img-2.jpg"
import img_3 from "./img-3.jpg"
import img_4 from "./img-4.jpg"
import img_5 from "./img-5.jpg"
import img_7 from "./img-7.png"
import screenshot_1 from "./screenshot-1.png"
import screenshot_2 from "./screenshot-2.png"
import screenshot_3 from "./screenshot-3.png"
import screenshot_4 from "./screenshot-4.png"

const Images = {
  logo,
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
  img_7,
  screenshot_1,
  screenshot_2,
  screenshot_3,
  screenshot_4,
}

export default Images