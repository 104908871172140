import { Box, Button, Link, Stack, styled } from "@mui/material"
import { Link as NavLink, useLocation } from "react-router-dom"
import { menus } from "../../constant/admin/Sidebar"
import Images from "../../assets/images"

const Image = styled("img")(() => ({}))
const SidebarContainer = styled("div")(() => ({
  top: "0",
  bottom: "0",
  zIndex: "200",
  width: "250px",
  height: "100vh",
  position: "fixed",
  overflowY: "auto",
  backgroundColor: "#ceffff",
  transitionDuration: "350ms",
}))
function Sidebar({ active, sidebarDeActive }) {
  const { pathname } = useLocation()

  return (
    <SidebarContainer
      as="aside"
      sx={{ left: { xs: active ? "0" : "-100%", laptop: "0" } }}
    >
      <Box sx={{ textAlign: "center", pb: 0.5, pt: 1 }}>
        <Link
          to="/"
          component={NavLink}
          sx={{ display: "inline-block" }}
        >
          <Image
            sx={{
              height: "50px",
              width: "100%",
              objectFit: "contain"
            }}
            alt="logo"
            src={Images?.logo}
          />
        </Link>
      </Box>

      <Stack sx={{ px: 2 }} spacing={1}>
        {menus?.map((menu, i) => (
          <Button
            sx={{
              fontSize: "17px",
              fontWeight: "500",
              fontFamily: "Poppins",
              justifyContent: "start",
              textTransform: "capitalize",
              boxShadow: "none !important",
              color: pathname === menu?.path ? "white" : "#110e24",
              ":hover": pathname !== menu?.path && { bgcolor: "white" },
              bgcolor: pathname === menu?.path ? "#00cada" : "transparent",
            }}
            key={i}
            to={menu?.path}
            variant="contained"
            startIcon={menu?.icon}
            LinkComponent={NavLink}
            onClick={sidebarDeActive}
          >{menu?.name}
          </Button>
        ))
        }
      </Stack>
    </SidebarContainer>
  )
}

export default Sidebar