import { getAllContactRequests } from "../../../redux/adminSlices/contact.slice"
import { requestsContactHeader } from "../../../constant/admin/Tables"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import Header from "../../../components/admin/Header"
import MuiIcons from "../../../assets/js/muiIcons"
import { Link } from "react-router-dom"
import moment from "moment"

import {
  Box, IconButton, Pagination, Skeleton, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material"

function AllRequest() {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { request } = useSelector(state => state.requestAdSlice)

  const getRequestsByPage = (n) => {
    dispatch(getAllContactRequests({
      page: n,
      // search: searchRef?.current?.value
      search: null
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  useEffect(() => {
    dispatch(getAllContactRequests({
      page: null,
      search: null
    })
    ).unwrap().then((res) => {
      setData(res)
    })

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            color: "primary.main",
            fontFamily: "Montserrat",
          }}
          component="h4"
        >Contacts
        </Typography>
      </Header>

      <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              color: "#110e24",
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >Found contacts : {data?.totalContacts || 0}
          </Typography>
          {/* <Box sx={{ maxWidth: "350px", ml: "auto" }}>
            <SearchInput />
          </Box> */}
        </Box>

        <TableContainer
          sx={{
            maxWidth: "100%",
            borderRadius: "8px",
            boxShadow: "0px 0px 20px 0px #4545452e"
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {requestsContactHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={{
                      py: 1,
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "500",
                      minWidth: "100px",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {request?.allLoading ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(6)?.fill()?.map((cell, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.contacts?.map((req, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        fontWeight: "500",
                        textWrap: "nowrap",
                        fontFamily: "Poppins",
                      }}
                    >{moment(req?.createdAt).format("DD-MMM-YYYY")}.
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{req?.role}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{req?.fullName}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{req?.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{req?.message}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    >
                      <IconButton
                        size="small"
                        sx={{ mr: 1 }}
                        LinkComponent={Link}
                        to={`/admin/contact/${req?._id}`}
                      ><MuiIcons.RemoveRedEyeOutlined sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.contacts?.length && !request?.allLoading) &&
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          {!request?.allLoading ?
            <Pagination
              as="div"
              color="#00cada"
              shape="rounded"
              boundaryCount={3}
              count={data?.pages?.totalPages}
              page={+data?.pages?.currentPage || 1}
              onChange={(_, n) => { getRequestsByPage(n) }}
            /> :
            <Stack direction="row" spacing={1}>
              {new Array(6).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  sx={{
                    height: "30px",
                    width: "30px",
                    transform: "none"
                  }}
                />
              ))
              }
            </Stack>
          }
        </Box>
      </Box>
    </Fragment>
  )
}

export default AllRequest