import CreateProperty from "./dialogs/CreateProperty"
import DeleteUser from "./dialogs/DeleteUser"
import OtpDialog from "./dialogs/OtpDialog"
import { useSelector } from "react-redux"
import { Dialog } from "@mui/material"
import CreateUser from "./dialogs/CreateUser"
import AddAdmin from "./dialogs/AddAdmin"
import DeleteProperty from "./dialogs/DeleteProperty"

function DialogContainer() {
  const { show, type } = useSelector(state => state.otherSlice)

  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="xs"
      scroll="paper"
      id="#MuiDialog"
      sx={{
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(1px)",
          backgroundColor: "#00000050"
        },
      }}
    >
      {type === "AddAdmin" && <AddAdmin />}
      {type === "OtpDialog" && <OtpDialog />}
      {type === "CreateUser" && <CreateUser />}
      {type === "DeleteUser" && <DeleteUser />}
      {type === "DeleteProperty" && <DeleteProperty />}
      {type === "CreateProperty" && <CreateProperty />}
    </Dialog>
  )
}

export default DialogContainer