import { createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import AdminRoutes from "./admin.routes"
import GuestRoutes from "./guest.routes"
import UserRoutes from "./users.routes"

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {GuestRoutes}
      {UserRoutes}
      {AdminRoutes}
    </>
  )
)

export default router