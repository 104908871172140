import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,

      mobile: 0,
      tablet: 640,
      laptop: 1025,
      desktop: 1200,
    },
  },
  palette: {
    primary: {
      light: "#1ce7f4",
      main: "#00cada",
      dark: "#02c3d2",
    },
    secondary: {
      main: "#0d1b2a",
      dark: "#1B1B1B",
      light: "#676767",
    }
  }
});

export default theme