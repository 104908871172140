import { toastError } from "../../redux/guestSlices/other.slice"
import { useNavigate, useSearchParams } from "react-router-dom"
import CustomInput from "../../components/common/CustomInput"
import { useDispatch, useSelector } from "react-redux"
import { checkToken } from "../../utils/helper"
import { useEffect } from "react"
import {
  Box, Button, CircularProgress,
  Container, Grid2 as Grid,
  Paper, styled,
} from "@mui/material"
import { resetPasswordReq } from "../../redux/userSlices/auth.slice"

const Paragraph = styled("p")(() => ({}))
function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLogin = checkToken(dispatch)
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const { user } = useSelector(state => state.authSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.token = token
    if ([formData?.password, formData?.confPassword]?.some(
      (field) => field?.trim() === ""
    )) {
      return dispatch(toastError("Password is required!"))
    }
    if (formData?.password !== formData?.confPassword) {
      return dispatch(toastError("Password not matched!"))
    }

    dispatch(resetPasswordReq(formData))
  }

  useEffect(() => {
    if (isLogin) { return navigate("/") }

    // eslint-disable-next-line
  }, [])

  return (
    <Box component="section" sx={{ bgcolor: "#f0f9ff", height: "100%" }}>
      <Container sx={{ height: "100%" }}>
        <Box sx={{ display: "grid", placeContent: "center", height: "100%", py: 4 }}>
          <Box
            sx={{
              p: 3,
              maxWidth: "450px",
              borderRadius: "8px",
              boxShadow: "0px 0px 12px 0px #00000024"
            }}
            component={Paper}
          >
            <Paragraph
              sx={{
                mb: 2,
                color: "#110e24",
                fontSize: "35px",
                fontWeight: "600",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >Reset password
            </Paragraph>
            <Paragraph
              sx={{
                mb: 1,
                color: "#110e24",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >Please enter the new password.
            </Paragraph>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid size={12}>
                  <CustomInput
                    name="password"
                    type="password"
                    label="password"
                  />
                </Grid>
                <Grid size={12}>
                  <CustomInput
                    name="confPassword"
                    type="password"
                    label="confrim password"
                  />
                </Grid>
                <Grid size={12}>
                  <Button
                    sx={{
                      width: "100%",
                      color: "white",
                      fontSize: "16px",
                      bgcolor: "#00cada",
                      fontWeight: "400",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      textTransform: "capitalize"
                    }}
                    type="submit"
                  >Submit
                    {user?.loading &&
                      <CircularProgress
                        size={25}
                        thickness={5}
                        sx={{
                          ml: 1,
                          display: "flex",
                          color: "white"
                        }}
                      />
                    }
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ResetPassword