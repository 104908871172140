import { getAllRequests } from "../../../redux/adminSlices/request.slice"
import { requestsTableHeader } from "../../../constant/admin/Tables"
import SearchInput from "../../../components/common/SearchInput"
import { Fragment, useEffect, useRef, useState } from "react"
import { Link, useOutletContext } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import MuiIcons from "../../../assets/js/muiIcons"
import { grey, red } from "@mui/material/colors"
import moment from "moment"

import {
  Avatar, Box, Chip, IconButton, Pagination, Skeleton, Stack,
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography
} from "@mui/material"

function AllRequest() {
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const { sidebarActive } = useOutletContext()
  const { request } = useSelector(state => state.requestAdSlice)

  // Handle get all requests
  const handleGetAll = (page = null, search = null) => {
    dispatch(getAllRequests({
      page,
      search
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  // Handle search requests
  let searchIntervel
  const handleSearch = () => {
    clearInterval(searchIntervel)
    searchIntervel = setTimeout(() => {
      handleGetAll(null, searchRef?.current?.value)
    }, 1500)
  }

  useEffect(() => {
    handleGetAll()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header sidebarActive={sidebarActive}>
        <Box sx={{ maxWidth: "250px" }}>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              color: "#110e24",
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >Found Request : {data?.totalRequest}
          </Typography>
        </Box>

        <TableContainer
          sx={{
            maxWidth: "100%",
            borderRadius: "8px",
            boxShadow: "0px 0px 20px 0px #4545452e"
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {requestsTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={{
                      py: 1,
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "500",
                      minWidth: "100px",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {request?.loadingAll ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(6)?.fill()?.map((cell, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.allRequest?.map((req, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        fontWeight: "500",
                        textWrap: "nowrap",
                        fontFamily: "Poppins",
                      }}
                    >{moment(req?.createdAt).format("DD-MMM-YYYY")}.
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    ><Avatar
                        sx={{
                          width: "35px",
                          height: "35px",
                          color: "white",
                          bgcolor: "#00cada",
                        }}
                        src={req?.fileURL}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{req?.user?.fullName}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{req?.user?.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        fontFamily: "Poppins",
                      }}
                      align="left"
                    >
                      <Chip
                        sx={{
                          "span": {
                            color: "white",
                            lineHeight: "1",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            overflow: "unset",
                            textTransform: "capitalize",
                          },
                          bgcolor: req?.status === "rejected" ?
                            red[500] : req?.status === "pending" ?
                              grey[500] : "#00cada"
                        }}
                        size="small"
                        component="span"
                        label={req?.status}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    >
                      <IconButton
                        size="small"
                        sx={{ mr: 1 }}
                        LinkComponent={Link}
                        to={`/admin/request/${req?._id}`}
                      ><MuiIcons.RemoveRedEyeOutlined sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.allRequest?.length && !request?.loadingAll) &&
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          {!request?.loadingAll ?
            <Pagination
              as="div"
              color="#00cada"
              shape="rounded"
              boundaryCount={3}
              count={data?.pages?.totalPages}
              page={+data?.pages?.currentPage || 1}
              onChange={(_, n) => { handleGetAll(n) }}
            /> :
            <Stack direction="row" spacing={1}>
              {new Array(6).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  sx={{
                    height: "30px",
                    width: "30px",
                    transform: "none"
                  }}
                />
              ))
              }
            </Stack>
          }
        </Box>
      </Box>
    </Fragment>
  )
}

export default AllRequest