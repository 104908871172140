import { Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { checkAdminToken } from "../../utils/helper"

function AdminProtected({ children }) {
  const dispatch = useDispatch()

  return (
    checkAdminToken(dispatch) ?
      children :
      <Navigate to="/" replace />
  )
}

export default AdminProtected