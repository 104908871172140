import { Box, Skeleton, styled } from "@mui/material"
import { useState } from "react"

const Image = styled("img")(() => ({}))
function ImgWithSkel({ alt, src, diamention }) {
  const [show, setShow] = useState(true)

  return (
    <Box
      sx={{
        width: diamention?.width || "100%",
        height: diamention?.height || "100%",
        minWidth: diamention?.minWidth || "100%",
      }}
    >
      {show &&
        <Skeleton
          sx={{
            transform: "none",
            width: diamention?.width || "100%",
            height: diamention?.height || "100%",
          }}
          animation="wave"
        />
      }
      <Image
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "6px",
          display: show ? "none" : "block"
        }}
        src={src}
        alt={alt || "img"}
        onLoad={() => { setShow(false) }}
      />
    </Box>
  )
}

export default ImgWithSkel