import { userAuthenticationReq } from "../../redux/userSlices/auth.slice"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import SetNewPassword from "./SetNewPassword"
import { useParams } from "react-router-dom"
import Login from "./Login"

function Authentication() {
  const { userId } = useParams()
  const dispatch = useDispatch()
  const { auth } = useSelector(state => state.authSlice)
  const [authentication, setAuthentication] = useState(false)

  const handleAuthentication = () => {
    dispatch(userAuthenticationReq(userId)
    ).unwrap().then((res) => {
      setAuthentication(res?.passFlag)
    })
  }

  useEffect(() => {
    handleAuthentication()

    // eslint-disable-next-line
  }, [])

  return (
    auth?.loading ?
      <Box sx={{ display: "grid", placeContent: "center", height: "100%" }}>
        <CircularProgress
          size={70}
          thickness={6}
        />
      </Box> :
      <Fragment>
        {authentication ?
          <Login /> :
          <SetNewPassword />
        }
      </Fragment>
  )
}

export default Authentication