import { faqs } from "../../constant/guest/Faqs"
import MuiIcons from "../../assets/js/muiIcons"
import { Link } from "react-router-dom"
import { useState } from "react"

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button,
  Container, Grid2 as Grid, Typography
} from "@mui/material"

function FAQs() {
  const [active, setActive] = useState(0)

  // Handle active tab 
  const handleActive = (index) => {
    setActive(active !== index ? index : false)
  };

  return (
    <Box component="section" sx={{ py: 6 }}>
      <Container maxWidth="xxl">
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid size={{ xs: 12, md: 9 }}>
            <Box
              sx={{
                color: "#110e24",
                fontSize: "60px",
                fontWeight: "600",
                fontFamily: "Poppins",
                textAlign: { xs: "center", laptop: "left" },
              }}
            >FAQs
            </Box>
          </Grid>

          {faqs?.map((faq, i) => (
            <Grid key={i} size={{ xs: 12, md: 9 }}>
              <Accordion
                sx={{
                  overflow: "hidden",
                  boxShadow: active === i ?
                    "#00000026 0px 4px 8px 0px" :
                    "#00000026 0px 2px 4px 0px"
                }}
                expanded={active === i}
                onChange={() => { handleActive(i) }}
              >
                <AccordionSummary
                  expandIcon={<MuiIcons.ArrowForwardIosRounded
                    sx={{ color: active === i ? "#68639b" : "", }}
                  />}
                  sx={{
                    px: 2,
                    py: 1.5,
                    minHeight: "auto !important",
                    ".MuiAccordionSummary-content": { my: "0 !important" },
                    ".MuiAccordionSummary-expandIconWrapper": {
                      p: "4px",
                      borderRadius: "20px",
                      border: "1px solid",

                      borderColor: active === i ? "#68639b" : "#0000008a",
                      transform: active === i ?
                        "rotate(90deg) !important" :
                        "rotate(0deg) !important",
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      color: active === i ? "#68639b" : "",
                    }}
                  >{faq?.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#68639b",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                    }}
                  >{faq?.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))
          }

          <Grid size={{ xs: 12, md: 9 }}>
            <Box
              sx={{
                p: 5,
                my: 10,
                color: "white",
                display: "grid",
                bgcolor: "#110e24",
                borderRadius: "20px",
                fontFamily: "Poppins",
                placeContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: { xs: "22px", md: "25px" },
                }}
                component="h6"
              >Can't find your answer?
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  sx={{
                    mt: 4,
                    color: "white",
                    fontSize: "18px",
                    minWidth: "180px",
                    fontWeight: "400",
                    bgcolor: "#00cada",
                    borderRadius: "8px",
                    fontFamily: "Poppins",
                    textTransform: "unset"
                  }}
                  size="large"
                  LinkComponent={Link}
                  to="/support"
                >Contact us
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default FAQs