import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getSingleRequest } from "../userSlices/request.slice"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"

// Get all requests
export const getAllContactRequests = createAsyncThunk("getAllContactRequests", ({ page, search }, { rejectWithValue, dispatch }) => {
  const params = {}
  if (page) { params.page = page }
  if (search) { params.search = search }
  try {
    const response = axios.get(`${BASEURI}/admin/all-contacts`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const contactRequests = createAsyncThunk("contactRequests", (id, { dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/admin/contacts/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.contact
      }
    }).catch((err) => {
      console.log(err)
      if (err?.response?.data?.error) {
        return dispatch(toastError(err?.response?.data?.error))
      } else {
        return dispatch(toastError("Something went wrong!"))
      }
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  request: {}
}
const request = createSlice({
  name: "request",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all requests
    builder.addCase(getAllContactRequests.pending, (state) => {
      state.request.allLoading = true
    })
    builder.addCase(getAllContactRequests.fulfilled, (state) => {
      delete state.request.allLoading
    })
    builder.addCase(getAllContactRequests.rejected, (state) => {
      delete state.request.allLoading
    })

    // Get approve/reject request
    builder.addCase(contactRequests.pending, (state) => {
      state.request.sLoading = true
    })
    builder.addCase(contactRequests.fulfilled, (state) => {
      delete state.request.sLoading
    })
    builder.addCase(contactRequests.rejected, (state) => {
      delete state.request.sLoading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default request.reducer