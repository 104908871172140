import UserProtected from "../components/protected/UserProtected"
import SingleRequest from "../pages/user/request/SingleRequest"
import AllRequest from "../pages/user/request/AllRequest"
import Layout from "../components/user/Layout"
import Upload from "../pages/user/Upload"
import { Route } from "react-router-dom"

const UserRoutes = (
  <Route path="/user" element={<Layout />}>
    <Route path="upload" element={<UserProtected><Upload /></UserProtected>} />
    <Route path="all-request" element={<UserProtected><AllRequest /></UserProtected>} />
    <Route path="request/:requestId" element={<UserProtected><SingleRequest /></UserProtected>} />
  </Route>
)

export default UserRoutes