import { userVarifyEmailReq } from "../../../redux/userSlices/auth.slice"
import { hideDialog } from "../../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import OTPInput from "react-otp-input"
import { useState } from "react"
import {
  Box, CircularProgress, DialogContent, DialogTitle,
  Divider, IconButton, Paper, Typography
} from "@mui/material"

function OtpDialog() {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState("")
  const { user } = useSelector(state => state.authSlice)

  const handleVarify = (value) => {
    setOtp(value)
    if (value.length === 6) {
      dispatch(userVarifyEmailReq({ email: user?.email, otp: value }))
    }
  }
  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="OtpDialog">
      <DialogTitle component="div" className="p-2 relative">
        <Box className=" inline-block absolute top-[50%] right-[10px] translate-y-[-50%]">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><MuiIcons.ClearRounded
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: "24px",
            color: "#00cada",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "poppins",
          }}
          component="h6"
        >Email varification!
        </Typography>
      </DialogTitle>
      <Divider className="!border-gray-500" />
      <DialogContent>
        <Typography
          sx={{
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "poppins",
          }}
        >Please enter the verification code sent to<br />{user?.email}</Typography>
        <OTPInput
          containerStyle="otp-parent justify-center gap-[4px] my-6"
          value={otp}
          numInputs={6}
          renderSeparator={<span> </span>}
          onInput={() => { console.log(otp) }}
          onChange={(value) => { handleVarify(value) }}
          renderInput={(props) => <input {...props} />}
        />
        {user?.loading &&
          <Box className="p-8 grid place-content-center">
            <CircularProgress
              size={50}
              thickness={4}
            />
          </Box>
        }
      </DialogContent>
    </Paper>
  )
}

export default OtpDialog