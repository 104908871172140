import { array1, links, services, violations } from "../../constant/guest/Home"
import { Link as NavLink } from "react-router-dom"
import MuiIcons from "../../assets/js/muiIcons"
import Images from "../../assets/images"
import { Fragment } from "react"
import {
  Box, Button, Container,
  Grid2 as Grid, ListItem, ListItemIcon, ListItemText, styled,
  Typography
} from "@mui/material"
import { grey } from "@mui/material/colors"


const Span = styled("span")(() => ({}))
const Image = styled("img")(() => ({}))
const Paragraph = styled("p")(() => ({}))
function Home() {

  return (
    <Fragment>
      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            color: grey[900],
            bgcolor: "white",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box sx={{ py: 4, minHeight: "450px" }}>
              <Grid
                container
                spacing={{ xs: 4, lg: 1 }}
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                }}
              >
                <Grid size={{ xs: 12, md: 10, xl: 7 }} sx={{ textAlign: { xs: "center", md: "left" } }}>
                  <Typography
                    sx={{
                      mb: 3,
                      lineHeight: "1.2",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: { xs: "30px", md: "40px" },
                    }}
                    component="h1"
                  >AI-driven resident enabled property compliance system
                  </Typography>
                  <Box
                    sx={{
                      mb: 3,
                      mx: "auto",
                      textAlign: "left",
                      width: { xs: "min-content", md: "auto" }
                    }}
                  >
                    <Grid
                      container
                      spacing={{ xs: 1, md: 2 }}
                      sx={{ justifyContent: "center" }}
                    >
                      {links?.map((link, i) => (
                        <Grid
                          key={i}
                          size={{ xs: 12, md: 6 }}
                          sx={{ display: "inline-block" }}
                        >
                          <Button
                            sx={{
                              fontWeight: "500",
                              textWrap: "nowrap",
                              letterSpacing: "1px",
                              fontFamily: "Poppins",
                              textTransform: "unset",
                              textDecoration: "none",
                              boxShadow: "none !important",
                              fontSize: { xs: "14px", md: "15px" },
                              bgcolor: "transparent !important",
                              color: `${grey[900]} !important`,
                              justifyContent: "left"
                            }}
                            size="small"
                            disableRipple
                            variant="contained"
                            LinkComponent={NavLink}
                            startIcon={<MuiIcons.PlayArrowOutlined
                              sx={{
                                fontSize: {
                                  xs: "24px !important",
                                  md: "34px !important",
                                }
                              }}
                            />}
                          >{link}
                          </Button>
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Box>
                  <Button
                    sx={{
                      color: "white",
                      fontWeight: "400",
                      textWrap: "nowrap",
                      bgcolor: "#00cada",
                      borderRadius: "30px",
                      p: { md: "8px 22px" },
                      fontFamily: "Poppins",
                      textTransform: "unset",
                      textDecoration: "none",
                      boxShadow: "none !important",
                      transition: "all ease 350ms",
                      fontSize: { xs: "18px", md: "20px" },
                    }}
                    size="small"
                    to="/support"
                    variant="contained"
                    LinkComponent={NavLink}
                  >Book a Demo
                  </Button>
                  <Button
                    sx={{
                      ml: 1,
                      fontWeight: "400",
                      textWrap: "nowrap",
                      borderRadius: "30px",
                      p: { md: "8px 22px" },
                      fontFamily: "Poppins",
                      textTransform: "unset",
                      textDecoration: "none",
                      transition: "all ease 350ms",
                      boxShadow: "none !important",
                      color: `${grey[900]} !important`,
                      bgcolor: "transparent !important",
                      fontSize: { xs: "18px", md: "20px" },
                    }}
                    size="small"
                    to="/product"
                    variant="contained"
                    LinkComponent={NavLink}
                  >Learn more
                  </Button>
                </Grid>
                <Grid size={{ xs: 12, xl: 5 }}>
                  <Image
                    alt="img"
                    sx={{
                      width: "100%",
                      height: "100%",
                      maxHeight: { xs: "300px", md: "400px", lg: "auto" },
                      objectFit: "contain"
                    }}
                    src={Images?.img_7}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            py: 4,
            color: "white",
            bgcolor: "#110e24",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box
              sx={{
                py: 4,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  mb: 2.5,
                  lineHeight: "1.2",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                  fontSize: { xs: "32px", md: "44px" },
                }}
                component="h5"
              >Residents are sick of the crap
              </Typography>
            </Box>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ height: "100%" }}>
                <Grid container spacing={4} sx={{ height: "100%" }}>
                  {services?.map((service, i) => (
                    <Grid
                      key={i}
                      size={{ xs: 12, sm: 6, md: 4 }}
                      sx={{
                        gap: "12px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          mx: "auto",
                          width: "100%",
                          color: "white",
                          display: "grid",
                          aspectRatio: "1/1",
                          bgcolor: "#68639b",
                          borderRadius: "500px",
                          placeContent: "center",
                          maxWidth: { xs: "100px", md: "150px" },
                        }}
                      >{service?.icon}
                      </Box>
                      <Paragraph
                        sx={{
                          fontWeight: "600",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "20px", md: "30px" },
                        }}
                      >{service?.title}
                      </Paragraph>
                      <Paragraph
                        sx={{
                          fontWeight: "300",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "15px", md: "16px" },
                        }}
                      >{service?.subTitle}
                      </Paragraph>
                    </Grid>
                  ))
                  }

                  <Grid size={12} sx={{ textAlign: "center" }}>
                    <Button
                      sx={{
                        fontSize: "20px",
                        color: "#68639b",
                        bgcolor: "white",
                        minWidth: "200px",
                        fontWeight: "400",
                        textWrap: "nowrap",
                        borderRadius: "30px",
                        fontFamily: "Poppins",
                        textTransform: "unset",
                        textDecoration: "none",
                        border: "2px solid #68639b",
                        boxShadow: "none !important",
                        transition: "all ease 350ms",
                      }}
                      size="large"
                      to="/product"
                      variant="outlined"
                      LinkComponent={NavLink}
                    >Learn more
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            py: 4,
            color: grey[900],
            bgcolor: "#ebffff",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box
              sx={{
                py: 4,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  mb: 2.5,
                  lineHeight: "1.2",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                  fontSize: { xs: "32px", md: "44px" },
                }}
                component="h5"
              >Violations Supported
              </Typography>
            </Box>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ height: "100%" }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    height: "100%",
                    justifyContent: "center"
                  }}
                >
                  {violations?.map((service, i) => (
                    <Grid
                      key={i}
                      size={{ xs: 12, sm: 6, md: 4 }}
                      sx={{
                        gap: "12px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          mx: "auto",
                          width: "100%",
                          display: "grid",
                          aspectRatio: "1/1",
                          borderRadius: "500px",
                          placeContent: "center",
                          maxWidth: { xs: "100px", md: "150px" },
                        }}
                      >{service?.icon}
                      </Box>
                      <Paragraph
                        sx={{
                          fontWeight: "600",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "20px", md: "30px" },
                        }}
                      >{service?.title}
                      </Paragraph>
                      <Paragraph
                        sx={{
                          fontWeight: "300",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "15px", md: "16px" },
                        }}
                      >{service?.subTitle}
                      </Paragraph>
                    </Grid>
                  ))
                  }
                </Grid>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            px: 2,
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box sx={{ py: 4, minHeight: "450px" }}>
              <Grid
                container
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                }}
                spacing={{ xs: 4, lg: 1 }}
              >
                <Grid size={{ xs: 12, md: 10, xl: 7 }}>
                  <Typography
                    component="h1"
                    sx={{
                      mb: 3,
                      lineHeight: "1.2",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "30px", md: "40px" },
                    }}
                  >Community Compliant&copy; allows residents to report neighbor violations and share in the revenue.
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Grid container spacing={2}>
                      {array1?.map((item, i) => (
                        <Grid
                          key={i}
                          size={{ xs: 12, lg: 6 }}
                          sx={{
                            order: {
                              lg: [0, 2, 1, 3][i]
                            }
                          }}
                        >
                          <ListItem sx={{ alignItems: "flex-start" }}>
                            <ListItemIcon sx={{ minWidth: "45px" }}>
                              <Span
                                sx={{
                                  lineHeight: "1",
                                  color: "#00cada",
                                  fontSize: "60px",
                                  display: "inline-block",
                                }}
                              >{i + 1}</Span>
                            </ListItemIcon>
                            <ListItemText sx={{ flexGrow: "unset", m: "0" }}>
                              <Box>
                                <Paragraph
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >{item?.title}</Paragraph>
                                <Paragraph
                                  sx={{ fontSize: "15px" }}
                                >{item?.subTitle}</Paragraph>
                              </Box>
                            </ListItemText>
                          </ListItem>
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, xl: 5 }}>
                  <Image
                    alt="img"
                    sx={{
                      width: "100%",
                      height: "100%",
                      maxHeight: { xs: "300px", md: "400px", lg: "auto" },
                      objectFit: "contain"
                    }}
                    src={Images?.img_7}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Home