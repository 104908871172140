import { toastError, toastSuccess } from "./other.slice"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { BASEURI } from "../../utils/helper"
import axios from "axios"

// Handle login request
export const contactReq = createAsyncThunk("contactReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/contact-us`, data,

    ).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})