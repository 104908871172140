import { getAllRequestsByUser } from "../../../redux/adminSlices/request.slice"
import { singleUserRequestsTableHeader } from "../../../constant/admin/Tables"
import { getSingleUserReq } from "../../../redux/adminSlices/users.slice"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import Header from "../../../components/admin/Header"
import { Link, useParams } from "react-router-dom"
import MuiIcons from "../../../assets/js/muiIcons"
import { grey, red } from "@mui/material/colors"
import moment from "moment"

import {
  Avatar, Box, Button, Chip, Divider, Grid2 as Grid, IconButton,
  Pagination, Skeleton, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography
} from "@mui/material"

function SingleUser() {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [data, setData] = useState({})
  const { singleUser } = useSelector(state => state.usersSlice)
  const { request } = useSelector(state => state.requestAdSlice)

  // Get single user
  const handleGetUser = () => {
    dispatch(getSingleUserReq(userId))
  }

  // Handle get all request list
  const handleGetAll = (page = null) => {
    dispatch(getAllRequestsByUser({
      page,
      id: userId,
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  useEffect(() => {
    handleGetUser()
    handleGetAll()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header />

      <Box>
        <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
          {!singleUser?.loading ?
            <Grid container spacing={4} sx={{ alignItems: "center" }}>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Avatar
                  sx={{
                    color: "#00cada",
                    bgcolor: "#ceffff",
                    width: { xs: "75px", md: "100px" },
                    height: { xs: "75px", md: "100px" },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    color: "primary.main",
                    textTransform: "capitalize",
                    fontSize: { xs: "20px", md: "24px" },
                  }}
                  component="h2"
                >{singleUser?.fullName}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >{singleUser?.email}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                >Status:&nbsp;
                  <Chip
                    sx={{
                      "span": {
                        color: "white",
                        lineHeight: "1",
                        fontSize: "12px",
                        overflow: "unset",
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                      },
                      py: "2px",
                      height: "auto",
                      bgcolor: !singleUser?.status ?
                        grey[500] : "#00cada"
                    }}
                    size="small"
                    component="span"
                    label={singleUser?.status ? "active" : "deactive"}
                  />
                </Typography>
              </Grid>
              <Grid size={{ xs: "auto" }} sx={{ ml: "auto", alignSelf: "flex-start" }}>
                <Button
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    textTransform: "none",
                    fontFamily: "Poppins",
                    boxShadow: "none !important"
                  }}
                  variant="contained"
                  LinkComponent={Link}
                  to={`/admin/profile/${userId}`}
                >View profile
                </Button>
              </Grid>
            </Grid> :
            <Grid container spacing={4} sx={{ alignItems: "center" }}>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Skeleton
                  sx={{
                    width: { xs: "75px", md: "100px" },
                    height: { xs: "75px", md: "100px" },
                  }}
                  variant="circular"
                />
              </Grid>
              <Grid size={{ xs: 12, sm: "auto" }} sx={{ flexGrow: "1 !important" }}>
                <Skeleton
                  sx={{
                    transform: "none",
                    width: { xs: "75%", md: "250px" },
                    height: { xs: "24px", md: "32px" },
                  }}
                />
                <Skeleton
                  sx={{
                    mt: 1,
                    transform: "none",
                    width: { md: "350px" },
                    height: { xs: "16px", md: "18px" },
                  }}
                />
              </Grid>
            </Grid>
          }
        </Box>

        <Divider />

        <Box sx={{ p: 3, width: { xs: "100vw", laptop: "calc(100vw - 250px)" } }}>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center"
            }}
          >
            <Typography
              sx={{
                color: "#110e24",
                fontSize: "20px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >Found Request : {data?.totalRequest || 0}
            </Typography>
          </Box>

          <TableContainer
            sx={{
              maxWidth: "100%",
              borderRadius: "8px",
              boxShadow: "0px 0px 20px 0px #4545452e"
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {singleUserRequestsTableHeader?.map((cell, i) => (
                    <TableCell
                      key={i}
                      align="left"
                      sx={{
                        py: 1,
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "500",
                        minWidth: "100px",
                        bgcolor: "#00cada",
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                      }}
                    >{cell}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {request?.loadingAll ?
                  new Array(5)?.fill()?.map((_, i) => (
                    <TableRow key={i}>
                      {new Array(4)?.fill()?.map((cell, i) => (
                        <TableCell
                          key={i}
                          align="left"
                          sx={{
                            py: 0.5,
                            minWidth: "100px",
                          }}
                        ><Skeleton sx={{ height: "30px" }} />
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  )) :
                  data?.requests?.map((req, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        align="left"
                        sx={{
                          py: 1,
                          fontSize: "15px",
                          fontWeight: "500",
                          textWrap: "nowrap",
                          fontFamily: "Poppins",
                        }}
                      >{moment(req?.createdAt).format("DD-MMM-YYYY")}.
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ py: 1 }}
                      ><Avatar
                          sx={{
                            width: "35px",
                            height: "35px",
                            color: "white",
                            bgcolor: "#00cada",
                          }}
                          src={req?.fileURL}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          py: 1,
                          fontSize: "15px",
                          minWidth: "100px",
                          fontFamily: "Poppins",
                        }}
                        align="left"
                      >
                        <Chip
                          sx={{
                            "span": {
                              color: "white",
                              lineHeight: "1",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              overflow: "unset",
                              textTransform: "capitalize",
                            },
                            bgcolor: req?.status === "rejected" ?
                              red[500] : req?.status === "pending" ?
                                grey[500] : "#00cada"
                          }}
                          size="small"
                          component="span"
                          label={req?.status}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ py: 1 }}
                      >
                        <IconButton
                          size="small"
                          sx={{ mr: 1 }}
                          LinkComponent={Link}
                          to={`/admin/request/${req?._id}`}
                        ><MuiIcons.RemoveRedEyeOutlined sx={{ fontSize: "20px", color: "#00cada" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }

                {(!data?.requests?.length && !request?.loadingAll) &&
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      sx={{
                        py: 1,
                        color: "#110e24",
                        fontSize: "24px",
                        minWidth: "100px",
                        fontWeight: "500",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                    >No results found!
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 3 }}>
            {!singleUser?.loading ?
              <Pagination
                as="div"
                color="primary"
                shape="rounded"
                boundaryCount={3}
                count={data?.pages?.totalPages}
                page={+data?.pages?.currentPage || 1}
                onChange={(_, n) => { handleGetAll(n) }}
              /> :
              <Stack direction="row" spacing={1}>
                {new Array(6).fill().map((_, i) => (
                  <Skeleton
                    key={i}
                    sx={{
                      height: "30px",
                      width: "30px",
                      transform: "none"
                    }}
                  />
                ))
                }
              </Stack>
            }
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default SingleUser