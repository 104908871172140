export const features = [
  {
    key: "Time and Cost Savings :",
    content: "Automates routine compliance tasks, such as pet waste, off-leash, and parking violations, reducing manual oversight and operational costs.",
  },
  {
    key: "Enhanced Resident Engagement :",
    content: "Offers a user-friendly portal for residents to report issues, improving satisfaction.",
  },
  {
    key: "Lease Violation Monitoring :",
    content: "Uses AI to detect and report lease violations, preventing disputes.",
  },
  {
    key: "Improved Online Reputation :",
    content: "Efficiently manages complaints and violations, enhancing the property’s online image.",
  },
  {
    key: "Proactive Risk Management :",
    content: "Identifies and addresses potential compliance issues before they escalate.",
  },
]

export const benefits = [
  {
    title: "Property benefits",
    keys: [
      "Improved online reputation",
      "Increased untapped revenue",
      "Increased resident satisfaction",
      "Increased curb appeal",
    ]
  },
  {
    title: "Resident benefits",
    keys: [
      "Share in percentage of revenue",
      "Easily report a frustration point",
      "Live in a cleaner community",
      "Collaborative effort to keep community compliant",
    ]
  },
]

export const propertyBenefits = [
  "Improved online reputation",
  "Increased untapped revenue",
  "Increased resident satisfaction",
  "Increased curb appeal",
]

export const residentBenefits = [
  "Share in percentage of revenue",
  "Easily report a frustration point",
  "Live in a cleaner community",
  "Collaborative effort to keep community compliant",
]