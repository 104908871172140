import CustomInput from "../../components/common/CustomInput"
import { toastError } from "../../redux/guestSlices/other.slice"
import { contactReq } from "../../redux/guestSlices/contact.slice"
import { useDispatch } from "react-redux"
import {
  Box, Button, CircularProgress,
  Container, Grid2 as Grid,
  InputLabel, MenuItem, OutlinedInput,
  Paper, Select, styled,
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))
function Support() {
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if ([formData?.email, formData?.password]?.some(
      (field) => field?.trim() === ""
    )) {
      return dispatch(toastError("Please fill all required field!"))
    }
    dispatch(contactReq(formData))
    console.log(formData)
  }

  return (
    <Box component="section" sx={{ bgcolor: "#f0f9ff" }}>
      <Container maxWidth="xxl" sx={{ height: "100%" }}>
        <Box sx={{ display: "grid", height: "100%", py: 4 }}>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid size={{ xs: 12, sm: 10, lg: 6 }}>
              <Box
                sx={{
                  p: 3,
                  borderRadius: "8px",
                  boxShadow: "0px 0px 12px 0px #00000024"
                }}
                component={Paper}
              >
                <Paragraph
                  sx={{
                    mb: 2,
                    color: "#110e24",
                    fontSize: "35px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >Contact us
                </Paragraph>
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid size={12}>
                      <InputLabel
                        sx={{
                          mb: 0.5,
                          color: "#110e24",
                          display: "block",
                          textAlign: "start",
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                        }}
                        htmlFor="role"
                      >role
                      </InputLabel>
                      <Select
                        sx={{
                          width: "100%",
                          "svg": { color: "#110e24" },
                          ".MuiSelect-select": {
                            py: 1,
                            color: "#110e24",
                          },
                          "fieldset": {
                            outline: "none",
                            border: "1px solid #110e24 !important",
                          }
                        }}
                        id="role"
                        name="role"
                        displayEmpty
                        inputProps={{ id: "role" }}
                        defaultValue={""}
                        input={<OutlinedInput inputProps={{ id: "role" }} />}
                      >
                        <MenuItem disabled value="">Choose Role</MenuItem>
                        {["Resident", "Property Manager", "Regional Manager", "Asset Manager", "Marketing Director", "Other"]?.map((item, i) => (
                          <MenuItem
                            key={i}
                            value={item}
                          >{item}
                          </MenuItem>
                        ))
                        }
                      </Select>
                    </Grid>

                    <Grid size={12}>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <CustomInput
                              name="fullName"
                              type="text"
                              label="name"
                            />
                          </Grid>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <CustomInput
                              name="email"
                              type="email"
                              label="email"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid size={12}>
                      <CustomInput
                        name="message"
                        type="textarea"
                        label="message"
                      />
                    </Grid>

                    <Grid size={12}>
                      <Box>
                        <Button
                          sx={{
                            minWidth: "150px",
                            color: "white",
                            fontSize: "16px",
                            bgcolor: "#00cada",
                            fontWeight: "400",
                            boxShadow: "none",
                            fontFamily: "Poppins",
                            textTransform: "capitalize"
                          }}
                          type="submit"
                        >Submit
                          {!true &&
                            <CircularProgress
                              size={25}
                              thickness={5}
                              sx={{
                                ml: 1,
                                display: "flex",
                                color: "white"
                              }}
                            />
                          }
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>

                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default Support