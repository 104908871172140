import { getSingleRequest } from "../../../redux/userSlices/request.slice"
import ImgWithSkel from "../../../components/common/ImgWithSkel"
import { useDispatch, useSelector } from "react-redux"
import { grey, red } from "@mui/material/colors"
import { useParams } from "react-router-dom"
import { Fragment, useEffect } from "react"
import moment from "moment"
import {
  Box, Chip, Container,
  Grid2 as Grid, Skeleton,
  styled, Typography
} from "@mui/material"

const Span = styled("span")(() => ({}))
function SingleRequest() {
  const dispatch = useDispatch()
  const { requestId } = useParams()
  const { singleRequest } = useSelector(state => state.requestSlice)

  useEffect(() => {
    dispatch(getSingleRequest(requestId))

    // eslint-disable-next-line
  }, [])

  return (
    <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
      <Container maxWidth="xxl">
        <Grid container spacing={3} sx={{ justifyContent: "center" }}>
          <Grid size={{ xs: 12, sm: 10, md: 8 }}>
            <ImgWithSkel
              diamention={{
                height: "350px",
              }}
              src={singleRequest?.fileURL}
            />
          </Grid>
          {singleRequest?.loading ?
            <Fragment>
              <Grid size={{ xs: 12, sm: 10, md: 8 }}>
                <Skeleton sx={{ height: "25px", transform: "none", mb: 1.5, width: "75%" }} />
                <Skeleton sx={{ height: "25px", transform: "none", width: "75%" }} />
              </Grid>
              <Grid size={{ xs: 12, sm: 10, md: 8 }}>
                <Skeleton sx={{ height: "20px", transform: "none", mb: 1.5 }} />
                <Skeleton sx={{ height: "20px", transform: "none", mb: 1.5 }} />
                <Skeleton sx={{ height: "20px", transform: "none", width: "50%" }} />
              </Grid>
            </Fragment> :
            <Grid size={{ xs: 12, sm: 10, md: 8 }}>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>File Name : </Span> {singleRequest?.fileName}
              </Typography>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Timestamp : </Span>&nbsp;
                {moment(singleRequest?.createdAt).format("DD/MM/YYYY - hh:MM A")}
              </Typography>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Violation Type : </Span>&nbsp;
                {singleRequest?.violationType}
              </Typography>
              {singleRequest?.note &&
                <Typography
                  sx={{
                    mb: 1.5,
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                ><Span sx={{ fontWeight: "500" }}>Note : </Span>&nbsp;
                  {singleRequest?.note}
                </Typography>
              }
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Status : </Span>&nbsp;
                <Chip
                  sx={{
                    "span": {
                      color: "white",
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    },
                    bgcolor: singleRequest?.status === "rejected" ?
                      red[500] : singleRequest?.status === "pending" ?
                        grey[500] : "#00cada"
                  }}
                  size="small"
                  component="span"
                  label={singleRequest?.status}
                />
              </Typography>
              {singleRequest?.promptResponse?.split("\n\n")?.map((text, i) => (
                <Typography
                  sx={{
                    mb: 1.5,
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                  key={i}
                  variant="body2"
                >{text}
                </Typography>
              ))
              }
            </Grid>
          }
        </Grid>
      </Container>
    </Box>
  )
}

export default SingleRequest