import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"

// Handle create property request
export const handleCreatePropertyReq = createAsyncThunk("handleCreatePropertyReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/property/create`, data, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle get all properties
export const handleGetAllPropertiesReq = createAsyncThunk("handleGetAllPropertiesReq", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
  }
  try {
    const response = axios.get(`${BASEURI}/admin/property/get-all`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle get single property
export const handleGetSingleProperyReq = createAsyncThunk("handleGetAllPropertiesReq", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
  }
  try {
    const response = axios.get(`${BASEURI}/admin/property/${data?.id}`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle delete property
export const handleDeletePropertiesReq = createAsyncThunk("handleDeletePropertiesReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.delete(`${BASEURI}/admin/property/delete/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  property: {}
}
const property = createSlice({
  name: "property",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle create property request
    builder.addCase(handleCreatePropertyReq.pending, (state) => {
      state.property.loadingCreate = true
    })
    builder.addCase(handleCreatePropertyReq.fulfilled, (state, { payload }) => {
      delete state?.property?.loadingCreate
    })
    builder.addCase(handleCreatePropertyReq.rejected, (state) => {
      delete state?.property?.loadingCreate
    })

    // Handle get all properties
    builder.addCase(handleGetAllPropertiesReq.pending, (state) => {
      state.property.loadingAll = true
    })
    builder.addCase(handleGetAllPropertiesReq.fulfilled, (state, { payload }) => {
      delete state?.property?.loadingAll
    })
    builder.addCase(handleGetAllPropertiesReq.rejected, (state) => {
      delete state?.property?.loadingAll
    })

    // Handle get single property
    builder.addCase(handleDeletePropertiesReq.pending, (state) => {
      state.property.loadingDelete = true
    })
    builder.addCase(handleDeletePropertiesReq.fulfilled, (state, { payload }) => {
      delete state?.property?.loadingDelete
    })
    builder.addCase(handleDeletePropertiesReq.rejected, (state) => {
      delete state?.property?.loadingDelete
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default property.reducer