import { getSingleUserReq, handleUpdateUserReq } from "../../../redux/adminSlices/users.slice"
import { toastError } from "../../../redux/guestSlices/other.slice"
import CustomInput from "../../../components/common/CustomInput"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import { useParams } from "react-router-dom"
import { Fragment, useEffect } from "react"
import { grey } from "@mui/material/colors"

import {
  Avatar, Box, Button, Chip, CircularProgress, Divider,
  Grid2 as Grid, Skeleton, Typography
} from "@mui/material"


function EditProfile() {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { singleUser, user } = useSelector(state => state.usersSlice)

  // Get single user
  const handleGetUser = () => {
    dispatch(getSingleUserReq(userId))
  }

  // Handle update user
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if (formData?.password?.trim()) {
      if (!formData?.currentPassword?.trim()) {
        return dispatch(toastError("Current password is required!"))
      }
      if (formData?.confPassword?.trim() !== formData?.password?.trim()) {
        return dispatch(toastError("Confirm password mismatched!"))
      }
      delete formData?.confPassword
    } else {
      delete formData?.password
      delete formData?.confPassword
      delete formData?.currentPassword
    }
    // formData.image = image
    dispatch(handleUpdateUserReq({ id: userId, formData })
    ).unwrap().then((res) => {
      handleGetUser()
    })
  }

  useEffect(() => {
    handleGetUser()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header />
      <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
        {!singleUser?.loading ?
          <Grid container spacing={4} sx={{ alignItems: "center", pb: 3 }}>
            <Grid size={{ xs: 12, sm: "auto" }}>
              <Avatar
                sx={{
                  color: "#00cada",
                  bgcolor: "#ceffff",
                  width: { xs: "75px", md: "150px" },
                  height: { xs: "75px", md: "150px" },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: "auto" }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  textTransform: "capitalize",
                  fontSize: { xs: "24px", md: "32px" },
                }}
                component="h2"
              >{singleUser?.fullName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >{singleUser?.email}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                }}
              >Status:&nbsp;
                <Chip
                  sx={{
                    "span": {
                      color: "white",
                      lineHeight: "1",
                      fontSize: "12px",
                      overflow: "unset",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    },
                    py: "2px",
                    height: "auto",
                    bgcolor: !singleUser?.status ?
                      grey[500] : "#00cada"
                  }}
                  size="small"
                  component="span"
                  label={singleUser?.status ? "active" : "deactive"}
                />
              </Typography>
            </Grid>
          </Grid> :
          <Grid container spacing={4} sx={{ alignItems: "center", pb: 3 }}>
            <Grid size={{ xs: 12, sm: "auto" }}>
              <Skeleton
                sx={{
                  width: { xs: "75px", md: "150px" },
                  height: { xs: "75px", md: "150px" },
                }}
                variant="circular"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: "auto" }} sx={{ flexGrow: "1 !important" }}>
              <Skeleton
                sx={{
                  transform: "none",
                  width: { xs: "75%", md: "250px" },
                  height: { xs: "24px", md: "32px" },
                }}
              />
              <Skeleton
                sx={{
                  mt: 1,
                  transform: "none",
                  width: { md: "350px" },
                  height: { xs: "16px", md: "18px" },
                }}
              />
            </Grid>
          </Grid>
        }

        <Divider />

        <Box sx={{ py: 3 }}>
          {singleUser?.loading ?
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              {new Array(5).fill().map((_, i) => (
                <Grid key={i} size={{ xs: 12 }} sx={{ flexGrow: "1 !important" }}>
                  <Skeleton
                    sx={{
                      transform: "none",
                      width: { xs: "75%", md: "350px" },
                      height: { xs: "24px", md: "32px" },
                    }}
                  />
                </Grid>
              ))
              }
            </Grid> :
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <Box sx={{ maxWidth: "350px" }}>
                    <CustomInput
                      name="fullName"
                      type="name"
                      label="name"
                      value={singleUser?.fullName}
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Box sx={{ maxWidth: "350px" }}>
                    <CustomInput
                      name="email"
                      type="email"
                      label="email"
                      value={singleUser?.email}
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Box sx={{ maxWidth: "350px" }}>
                    <CustomInput
                      name="currentPassword"
                      type="password"
                      label="current password"
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Box sx={{ maxWidth: "350px" }}>
                    <CustomInput
                      name="password"
                      type="password"
                      label="new password"
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Box sx={{ maxWidth: "350px" }}>
                    <CustomInput
                      name="confPassword"
                      type="password"
                      label="confirm password"
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Button
                    sx={{
                      ml: 1,
                      color: "white",
                      fontFamily: "Poppins",
                      textTransform: "unset",
                      boxShadow: "none !important",
                    }}
                    type="submit"
                    variant="contained"
                    // onClick={handleSubmit}
                    endIcon={user?.loadingUpdate &&
                      <CircularProgress
                        size={25}
                        thickness={5}
                        sx={{ color: "white" }}
                      />
                    }
                    disabled={user?.loadingUpdate}
                  >Update
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
        </Box>
      </Box>
    </Fragment>
  )
}

export default EditProfile