import { Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { checkToken } from "../../utils/helper"

function UserProtected({ children }) {
  const dispatch = useDispatch()

  return (
    checkToken(dispatch) ?
      children :
      <Navigate to="/" replace />
  )
}

export default UserProtected