import { IconButton, Input, InputLabel } from "@mui/material"
import { Fragment, useState } from "react"
import { uid } from "uid"
import MuiIcons from "../../assets/js/muiIcons"

function CustomInput({ id, label, type, name = "", value }) {
  id = id || uid(6)
  const [show, setShow] = useState(false)

  return (
    <Fragment>
      <InputLabel
        sx={{
          mb: 0.5,
          color: "#110e24",
          display: "block",
          textAlign: "start",
          fontFamily: "Poppins",
          textTransform: "capitalize",
        }}
        htmlFor={id}
      >{label}
      </InputLabel>
      <Input
        sx={{
          p: "4px 8px",
          width: "100%",
          borderRadius: "4px",
          border: "1px solid #110e24",
          ":after": { display: "none" },
          ":before": { display: "none" },

          "input": {
            color: "#110e24",
            fontSize: "15px",
            fontFamily: "Poppins",
          },
          "textarea": {
            height: "auto",
            color: "#110e24",
            fontSize: "15px",
            fontFamily: "Poppins",
          }
        }}
        endAdornment={
          type === "password" &&
          <IconButton
            size="small"
            onClick={() => { setShow(!show) }}
          >{!show ?
            <MuiIcons.RemoveRedEyeRounded sx={{ fontSize: "18px", color: "#110e24" }} /> :
            <MuiIcons.VisibilityOffRounded sx={{ fontSize: "18px", color: "#110e24" }} />
            }
          </IconButton>
        }
        id={id}
        name={name}
        defaultValue={value || ""}
        inputProps={type === "textarea" ? { rows: 4 } : {}}
        inputComponent={type === "textarea" ? "textarea" : "input"}
        type={(type !== "password" ? type : show ? "text" : "password") || "text"}
      />
    </Fragment>
  )
}

export default CustomInput