import { toastError } from "../redux/guestSlices/other.slice"
import router from "../routes/routes"
import { jwtDecode } from "jwt-decode"
import { Cookies } from "react-cookie"

const cookies = new Cookies()

// export const BASEURI = "http://localhost:5000/v1/api"
export const BASEURI = process.env.REACT_APP_BASEURI

export const checkToken = (dispatch) => {
  const token = cookies.get("token")
  try {
    if (token) {
      const decodedToken = jwtDecode(token)
      if (decodedToken?.exp * 1000 > Date.now()) {
        return true
      } else {
        cookies.remove("token", { path: "/" })
        dispatch({ type: "Logout", payload: "" })
        dispatch(toastError("Token expired. Please login again!"))
        return false
      }
    } else {
      return false
    }
  } catch (error) {
    const errorMessage = error?.message?.split(":")[0]
    dispatch(toastError(errorMessage))
  }
}

export const checkAdminToken = (dispatch) => {
  const token = cookies.get("adminToken")
  try {
    if (token) {
      const decodedToken = jwtDecode(token)
      if (decodedToken?.exp * 1000 > Date.now()) {
        return true
      } else {
        cookies.remove("adminToken", { path: "/" })
        dispatch({ type: "Logout", payload: "" })
        dispatch(toastError("Token expired. Please login again!"))
        return false
      }
    } else {
      return false
    }
  } catch (error) {
    const errorMessage = error?.message?.split(":")[0]
    dispatch(toastError(errorMessage))
  }
}

export const checkTokenErr = (err, dispatch) => {
  if (err.response.data.redirect) {
    cookies.remove("token", { path: "/" })
    dispatch({ type: "Logout", payload: "" })
    router.navigate(err.response.data?.location)
  }

  // if (err.response.data?.redirect) {
  //   dispatch(err.response.data?.location)
  // }
}

export const headers = () => {
  return {
    "withCredentials": true,
    "credentials": "include",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${cookies.get("token")}`,
  }
}

export const adminHeaders = () => {
  return {
    "withCredentials": true,
    "credentials": "include",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${cookies.get("adminToken")}`,
  }
}