import { hideDialog, toastError } from "../../../redux/guestSlices/other.slice"
import { inviteSubAdminReq } from "../../../redux/adminSlices/admin.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import { useParams } from "react-router-dom"
import CustomInput from "../CustomInput"

import {
  Button, CircularProgress, DialogActions, DialogContent,
  DialogTitle, Grid2 as Grid, IconButton, Paper, Typography
} from "@mui/material"

function AddAdmin() {
  const dispatch = useDispatch()
  const { id: propertyId } = useParams()
  const { properties } = useSelector(state => state.propertySlice)

  // Hide dialog
  const handleClose = () => {
    dispatch(hideDialog())
  }

  // Handle create property
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.propertyId = propertyId
    if ([formData?.fullName, formData?.email].some(
      (field) => field?.trim() === "" || !field
    )) {
      return dispatch(toastError("All fields are required!"))
    }
    dispatch(inviteSubAdminReq(formData))
  }

  return (
    <Paper component="form" onSubmit={handleSubmit}>
      <DialogTitle component="div" sx={{ position: "relative" }}>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Montserrat",
            color: "secondary.main",
          }}
          component="h6"
        >Create sub admin
        </Typography>
        <IconButton
          sx={{
            top: "50%",
            right: "10px",
            position: "absolute",
            transform: "translateY(-50%)"
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded sx={{ fontSize: "18px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="relative">
        <Grid container spacing={2}>
          <Grid size={12}>
            <CustomInput
              label="name"
              name="fullName"
            />
          </Grid>
          <Grid size={12}>
            <CustomInput
              name="email"
              type="email"
              label="email"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "unset",
          }}
          type="button"
          variant="outlined"
          onClick={handleClose}
        >Cancel
        </Button>
        <Button
          sx={{
            color: "white",
            minWidth: "100px",
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "unset",
            boxShadow: "none !important",
          }}
          endIcon={properties?.loading &&
            <CircularProgress
              size={22}
              thickness={5}
              sx={{
                color: properties?.loading ?
                  "white" : "#00000042"
              }}
            />
          }
          type="submit"
          disabled={properties?.loading}
          variant="contained"
        >Create
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default AddAdmin