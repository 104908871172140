import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  type: "",
  data: {},
  toast: {},
  show: false,
  refresh: true,
}
const other = createSlice({
  name: "other",
  initialState,
  reducers: {
    // Dialogs
    showDialogWithData: (state, { payload }) => {
      state.show = true
      state.type = payload?.type
      state.data = payload?.data
    },
    showDeleteUserDialog: (state, { payload }) => {
      state.show = true
      state.userId = payload?.id
      state.type = payload?.type
    },
    showDialog: (state, { payload }) => {
      state.show = true
      state.type = payload
    },
    hideDialog: (state) => {
      state.show = false
      state.type = ""
      state.data = {}
    },

    // Toaster
    toastError: (state, { payload }) => {
      state.toast.show = true
      state.toast.msg = payload
      state.toast.severity = "error"
    },
    toastSuccess: (state, { payload }) => {
      state.toast.show = true
      state.toast.msg = payload
      state.toast.severity = "success"
    },
    toastHide: (state) => {
      state.toast = {}
    },

    refresher: (state) => {
      state.refresh = !state.refresh
    }
  },
})

export const {
  toastSuccess, toastHide,
  showDeleteUserDialog, refresher,
  showDialog, hideDialog, toastError, showDialogWithData,
} = other.actions
export default other.reducer