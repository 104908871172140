import { benefits, features } from "../../constant/guest/Product"
import { Link as NavLink } from "react-router-dom"
import { array1, violations } from "../../constant/guest/Home"
import MuiIcons from "../../assets/js/muiIcons"
import Images from "../../assets/images"
import { Fragment } from "react"
import {
  Box, Button, Container, Grid2 as Grid,
  ListItem, ListItemIcon, ListItemText,
  Stack, styled, Typography
} from "@mui/material"
import { grey } from "@mui/material/colors"

const Image = styled("img")(() => ({}))
const Span = styled("span")(() => ({}))
const Paragraph = styled("p")(() => ({}))
const Strong = styled("strong")(() => ({ color: "#00cada", fontWeight: "500" }))
function Product() {
  return (
    <Fragment>
      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            color: grey[900],
            bgcolor: "white",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box sx={{ py: 4, minHeight: "450px" }}>
              <Grid
                container
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                }}
                spacing={{ xs: 4, lg: 1 }}
              >
                <Grid size={{ xs: 12, md: 10, xl: 7 }}>
                  <Typography
                    sx={{
                      mb: 3,
                      lineHeight: "1.2",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: { xs: "30px", md: "40px" },
                    }}
                    component="h1"
                  >AI-driven resident enabled property compliance system
                  </Typography>
                  <Typography
                    sx={{
                      mb: 3,
                      lineHeight: "1.2",
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", md: "16px" },
                    }}
                  >Our cutting-edge AI-driven property compliance system revolutionizes property management by integrating advanced technology to streamline compliance and enhance operational efficiency. Designed to save time and increase revenue for property owners and managers, this system offers a multifaceted approach to property management that delivers significant benefits across various aspects of operations.
                  </Typography>
                  <Button
                    sx={{
                      color: "white",
                      fontWeight: "400",
                      textWrap: "nowrap",
                      bgcolor: "#00cada",
                      borderRadius: "30px",
                      p: { md: "8px 22px" },
                      fontFamily: "Poppins",
                      textTransform: "unset",
                      textDecoration: "none",
                      boxShadow: "none !important",
                      transition: "all ease 350ms",
                      fontSize: { xs: "18px", md: "20px" },
                    }}
                    size="small"
                    to="/support"
                    variant="contained"
                    LinkComponent={NavLink}
                  >Book a Demo
                  </Button>
                </Grid>
                <Grid size={{ xs: 12, xl: 5 }} sx={{ textAlign: "center", display: "flex", gap: "8px", justifyContent: "center" }}>
                  <Image
                    alt="img"
                    sx={{
                      width: "100%",
                      height: "100%",
                      maxHeight: { xs: "300px", md: "400px", lg: "auto" },
                      objectFit: "contain"
                    }}
                    src={Images?.img_7}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            py: 4,
            color: "white",
            bgcolor: "#110e24",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box sx={{ py: 4 }}>
              <Typography
                sx={{
                  mb: 2.5,
                  lineHeight: "1.2",
                  fontWeight: "700",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: { xs: "32px", md: "44px" },
                }}
                component="h5"
              >Key Features & Benefits
              </Typography>
              <Grid container spacing={3}>
                {features?.map((feature, i) => (
                  <Grid key={i} size={12}>
                    <Paragraph
                    ><Strong>{feature?.key} </Strong>
                      {feature?.content}
                    </Paragraph>
                  </Grid>
                ))
                }
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box sx={{ borderRadius: "8px", }}>
          <Container maxWidth="xxl">
            <Box sx={{ py: 4 }}>
              <Grid
                container
                spacing={{ xs: 4, lg: 6 }}
                sx={{ placeContent: "center" }}
              >
                {benefits?.map((item, i) => (
                  <Grid key={i} size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        p: 3,
                        height: "100%",
                        bgcolor: "#f5f1ff",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          mb: 3,
                          fontSize: "28px",
                          fontWeight: "700",
                          fontFamily: "Poppins"
                        }}
                        component="h6"
                      >{item?.title}
                      </Typography>
                      <Stack spacing={1.5}>
                        {item?.keys?.map((key, j) => (
                          <ListItem key={j} sx={{ p: "0" }}>
                            <ListItemIcon sx={{ minWidth: "auto" }}>
                              <MuiIcons.HorizontalRuleRounded sx={{ mr: 1, color: "#8587f4" }} />
                            </ListItemIcon>
                            <ListItemText sx={{ m: "0" }}>{key}</ListItemText>
                          </ListItem>
                        ))
                        }
                      </Stack>
                    </Box>
                  </Grid>
                ))
                }
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            py: 4,
            color: grey[900],
            bgcolor: "#ebffff",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box
              sx={{
                py: 4,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  mb: 2.5,
                  lineHeight: "1.2",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                  fontSize: { xs: "32px", md: "44px" },
                }}
                component="h5"
              >Violations Supported
              </Typography>
            </Box>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ height: "100%" }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    height: "100%",
                    justifyContent: "center"
                  }}
                >
                  {violations?.map((service, i) => (
                    <Grid
                      key={i}
                      size={{ xs: 12, sm: 6, md: 4 }}
                      sx={{
                        gap: "12px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          mx: "auto",
                          width: "100%",
                          display: "grid",
                          aspectRatio: "1/1",
                          borderRadius: "500px",
                          placeContent: "center",
                          maxWidth: { xs: "100px", md: "150px" },
                        }}
                      >{service?.icon}
                      </Box>
                      <Paragraph
                        sx={{
                          fontWeight: "600",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "20px", md: "30px" },
                        }}
                      >{service?.title}
                      </Paragraph>
                      <Paragraph
                        sx={{
                          fontWeight: "300",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "15px", md: "16px" },
                        }}
                      >{service?.subTitle}
                      </Paragraph>
                    </Grid>
                  ))
                  }
                </Grid>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            px: 2,
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box sx={{ py: 4, minHeight: "450px" }}>
              <Grid
                container
                sx={{
                  placeContent: "center",
                  alignItems: "center"
                }}
                spacing={{ xs: 4, lg: 1 }}
              >
                <Grid size={{ xs: 12, md: 10, xl: 7 }}>
                  <Typography
                    component="h1"
                    sx={{
                      mb: 3,
                      lineHeight: "1.2",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "30px", md: "40px" },
                    }}
                  >Community Compliant&copy; allows residents to report neighbor violations and share in the revenue.
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Grid container spacing={2}>
                      {array1?.map((item, i) => (
                        <Grid
                          key={i}
                          size={{ xs: 12, lg: 6 }}
                          sx={{
                            order: {
                              lg: [0, 2, 1, 3][i]
                            }
                          }}
                        >
                          <Box sx={{ gap: 1, display: "flex" }}>
                            <Span
                              sx={{
                                lineHeight: "1",
                                color: "#00cada",
                                fontSize: "60px",
                                display: "inline-block",
                              }}
                            >{i + 1}</Span>
                            <Box>
                              <Paragraph
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                }}
                              >{item?.title}</Paragraph>
                              <Paragraph
                                sx={{ fontSize: "15px" }}
                              >{item?.subTitle}</Paragraph>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, xl: 5 }}>
                  <Image
                    alt="img"
                    sx={{
                      width: "100%",
                      height: "100%",
                      maxHeight: { xs: "300px", md: "400px", lg: "auto" },
                      objectFit: "contain"
                    }}
                    src={Images?.img_7}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Product
