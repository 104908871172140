import { combineReducers } from "@reduxjs/toolkit"

// Guest slices
import otherSlice from "./guestSlices/other.slice"

// User slices
import requestSlice from "./userSlices/request.slice"
import authSlice from "./userSlices/auth.slice"
import chatSlice from "./userSlices/chat.slice"

// Admin slices
import requestAdSlice from "./adminSlices/request.slice"
import contactSlice from "./adminSlices/contact.slice"
import propertySlice from "./adminSlices/property.slice"
import adminSlice from "./adminSlices/admin.slice"
import usersSlice from "./adminSlices/users.slice"

const rootReducer = combineReducers({
  authSlice,
  chatSlice,
  otherSlice,
  requestSlice,

  // Admin Slices
  adminSlice,
  usersSlice,
  contactSlice,
  propertySlice,
  requestAdSlice,
})

export default rootReducer