import { Box, Container, Grid2 as Grid, Skeleton, styled, Typography } from "@mui/material"
import { contactRequests } from "../../../redux/adminSlices/contact.slice"
import { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import moment from "moment"

const capitalizeFirstLetterOfEachWord = (string) => {
  if (!string) return "";
  return string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const Span = styled("span")(() => ({}))
function SingleRequest() {
  const dispatch = useDispatch()
  const { requestId } = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    dispatch(contactRequests(requestId)).then((res) => {
      console.log({ res });
      setData(res)
    })

    // eslint-disable-next-line
  }, [])

  return (
    <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
      <Container maxWidth="xxl">
        <Grid container spacing={3} sx={{ justifyContent: "center" }}>
          {
            console.log(data)
          }
          {data?.loading ?
            <Fragment>
              <Grid size={{ xs: 12, sm: 10, md: 8 }}>
                <Skeleton sx={{ height: "25px", transform: "none", mb: 1.5, width: "75%" }} />
                <Skeleton sx={{ height: "25px", transform: "none", width: "75%" }} />
              </Grid>
              <Grid size={{ xs: 12, sm: 10, md: 8 }}>
                <Skeleton sx={{ height: "20px", transform: "none", mb: 1.5 }} />
                <Skeleton sx={{ height: "20px", transform: "none", mb: 1.5 }} />
                <Skeleton sx={{ height: "20px", transform: "none", width: "50%" }} />
              </Grid>
            </Fragment> :
            <Grid size={{ xs: 12, sm: 10, md: 8 }}>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Role : </Span> {capitalizeFirstLetterOfEachWord(data?.payload?.role)}
              </Typography>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Timestamp : </Span>&nbsp;
                {moment(data?.payload?.createdAt).format("DD/MM/YYYY - hh:MM A")}
              </Typography>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Name : </Span>&nbsp;
                {capitalizeFirstLetterOfEachWord(data?.payload?.fullName)}
              </Typography>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Email : </Span>&nbsp;
                {data?.payload?.email}
              </Typography>
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              ><Span sx={{ fontWeight: "500" }}>Message : </Span>&nbsp;
                {data?.payload?.message}
              </Typography>
            </Grid>
          }
        </Grid>
      </Container>
    </Box>
  )
}

export default SingleRequest