import { showDialog, showDialogWithData } from "../../../redux/guestSlices/other.slice"
import { handleGetSingleProperyReq } from "../../../redux/adminSlices/property.slice"
import { handleUpdateUserReq } from "../../../redux/adminSlices/users.slice"
import { Link, useOutletContext, useParams } from "react-router-dom"
import { userTableHeader } from "../../../constant/admin/Tables"
import SearchInput from "../../../components/common/SearchInput"
import { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import MuiIcons from "../../../assets/js/muiIcons"

import {
  Avatar, Box, Button, Chip, CircularProgress, IconButton,
  Pagination, Skeleton, Stack, Switch, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material"

function SingleProperty() {
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const { id: propertyId } = useParams()
  const [data, setData] = useState({})
  const { sidebarActive } = useOutletContext()
  const { refresh } = useSelector(state => state.otherSlice)
  const { property } = useSelector(state => state.propertySlice)

  // Search users
  let searchIntervel
  const handleSearch = () => {
    clearInterval(searchIntervel)
    searchIntervel = setTimeout(() => {
      handleGetAll(null, searchRef?.current?.value)
    }, 1500)
  }

  // Get single property (All users list)
  const handleGetAll = (page = null, search = null) => {
    dispatch(handleGetSingleProperyReq({
      page,
      search,
      id: propertyId
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  // Handle create new user
  const handleCreate = () => {
    dispatch(showDialog("CreateUser"))
  }

  // Handle delete user 
  const handleDelete = (userId) => {
    dispatch(showDialogWithData({ type: "DeleteUser", data: { userId } }))
  }

  // Handle update user (Active/Deactive)
  const handleUpdate = (e, id) => {
    dispatch(handleUpdateUserReq({
      id,
      formData: { status: e?.target?.checked }
    })).unwrap().then(() => {
      handleGetAll()
    })
  }

  useEffect(() => {
    handleGetAll()

    // eslint-disable-next-line
  }, [refresh])

  return (
    <Fragment>
      <Header sidebarActive={sidebarActive}>
        <Box sx={{ maxWidth: "250px" }}>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>
      <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: { xs: "start", sm: "center" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {property?.loadingAll ?
            <Skeleton sx={{ height: "25px", width: "250px", transform: "none" }} /> :
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                textWrap: "nowrap",
                color: "primary.main",
                fontFamily: "Montserrat",
                textTransform: "capitalize",
              }}
              component="h4"
            >{data?.property?.name}
            </Typography>
          }
          <Box
            sx={{
              maxWidth: "350px",
              mt: { xs: 1, sm: "auto" },
              ml: { xs: "unset", sm: "auto" },
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{
                color: "white",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
                textTransform: "unset",
                boxShadow: "none !important"
              }}
              onClick={handleCreate}
              disabled={property?.loading}
              startIcon={<MuiIcons.AddRounded sx={{ fontSize: "18px" }} />}
            >Add user
            </Button>
          </Box>
        </Box>

        <Typography
          sx={{
            mb: 2,
            color: "#110e24",
            fontSize: "20px",
            fontWeight: "500",
            textWrap: "nowrap",
            fontFamily: "Poppins",
            textTransform: "capitalize",
          }}
        >Found users : {data?.totalUsers || 0}
        </Typography>

        <TableContainer
          sx={{
            maxWidth: "100%",
            borderRadius: "8px",
            boxShadow: "0px 0px 20px 0px #4545452e"
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {userTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={{
                      py: 1,
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "500",
                      minWidth: "100px",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {property?.loadingAll ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(7)?.fill()?.map((_, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.users?.map((user, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    ><Avatar
                        sx={{
                          width: "28px",
                          height: "28px",
                          color: "white",
                          bgcolor: "#00cada",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{user?.fullName}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{user?.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        fontFamily: "Poppins",
                      }}
                      align="left"
                    >
                      <Chip
                        sx={{
                          "span": {
                            color: "white",
                            lineHeight: "1",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            overflow: "unset",
                            textTransform: "capitalize",
                          },
                          bgcolor: "#00cada"
                        }}
                        size="small"
                        component="span"
                        label={user?.role}
                      />
                    </TableCell>
                    <TableCell>
                      {!true ?
                        // {(singleUser?.sLoading && loading === user?._id) ?
                        <CircularProgress
                          size={25}
                          thickness={6}
                          sx={{
                            color: "#00cada",
                            verticalAlign: "middle",
                            "span": { verticalAlign: "middle" }
                          }}
                        /> :
                        <Switch
                          size="small"
                          sx={{
                            ".MuiButtonBase-root": {
                              color: "#00cada !important"
                            },
                            ".MuiSwitch-track": {
                              bgcolor: user?.status ? "#1ce7f4 !important" : ""
                            },
                          }}
                          checked={user?.status}
                          onChange={(e) => { handleUpdate(e, user?._id) }}
                        />
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{user?.requestCount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    ><IconButton
                      size="small"
                      sx={{ mr: 1 }}
                      LinkComponent={Link}
                      to={`/admin/user/${user?._id}`}
                    ><MuiIcons.RemoveRedEyeOutlined sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => { handleDelete(user?._id) }}
                      >
                        <MuiIcons.DeleteOutlineOutlined
                          sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.users?.length && !property?.loadingAll) &&
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          {!property?.loading ?
            <Pagination
              as="div"
              color="primary"
              shape="rounded"
              boundaryCount={3}
              count={data?.pages?.totalPages}
              page={+data?.pages?.currentPage || 1}
              onChange={(_, n) => { handleGetAll(n) }}
            /> :
            <Stack direction="row" spacing={1}>
              {new Array(6).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  sx={{
                    height: "30px",
                    width: "30px",
                    transform: "none"
                  }}
                />
              ))
              }
            </Stack>
          }
        </Box>
      </Box>
    </Fragment >
  )
}

export default SingleProperty