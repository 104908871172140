import { Outlet } from "react-router-dom"
import Toaster from "../common/Toaster"
import Header from "../guest/Header"
import Footer from "../guest/Footer"
import { Box } from "@mui/material"

function Layout() {

  return (
    <Box
      sx={{
        display: "grid",
        minHeight: "100vh",
        gridTemplateRows: "auto 1fr auto"
      }}
    >
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
      <Toaster />
    </Box>
  )
}

export default Layout