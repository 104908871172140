import { hideDialog, showDialog, toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURI } from "../../utils/helper"
import router from "../../routes/routes"
import { Cookies } from "react-cookie"
import axios from "axios"

const cookies = new Cookies()

// Authentication request
export const userAuthenticationReq = createAsyncThunk("userAuthenticationReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/user/authentication/${id}`
    ).then((res) => {
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
        router?.navigate("/")
      }
      return res.data
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Set new password request
export const setNewPasswordReq = createAsyncThunk("userAuthenticationReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/set-password`, data,
    ).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
      } else {
        dispatch(toastError(res.data?.error))
      }
      router?.navigate("/")
      return res.data
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle login request
export const userLoginReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/login`, data
    ).then((res) => {
      if (res.data?.success) {
        if (res.data?.user?.role === "user") {
          cookies.set("token", res.data.token, { path: "/" })
          dispatch(toastSuccess(res.data?.message))
          router?.navigate("/user/upload")
          return res.data?.user
        } else {
          dispatch(toastError("Unauthorized login request"))
          return {}
        }
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
        if (res.data?.status === "email") {
          dispatch(showDialog("OtpDialog"))
          return { email: res.data?.email }
        }
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle signup request
export const userSignupReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/register`, data,
    ).then((res) => {
      if (res.data?.success) {
        cookies.set("token", res.data.token, { path: "/" })
        dispatch(toastSuccess(res.data?.message))
        router?.navigate("/user/upload")
        return res.data?.user
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
        if (res.data?.status === "email") {
          dispatch(showDialog("OtpDialog"))
          return { email: res.data?.email }
        }
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle varify email request
export const userVarifyEmailReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/varify-email`, data,
    ).then((res) => {
      dispatch(hideDialog())
      if (res.data?.success) {
        cookies.set("token", res.data.token, { path: "/" })
        dispatch(toastSuccess(res.data?.message))
        router?.navigate("/user/upload")
        return res.data?.user
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle forgot password request
export const forgotPasswordReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/forgot-password`, data,
    ).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
        return {}
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle reset password request
export const resetPasswordReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/reset-password`, data,
    ).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
        router.navigate("/")
        return {}
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  auth: {},
  user: {}
}
const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Authentication request
    builder.addCase(userAuthenticationReq.pending, (state) => {
      state.auth.loading = true
    })
    builder.addCase(userAuthenticationReq.fulfilled, (state, { payload }) => {
      delete state?.auth?.loading
      state.auth = payload || {}
    })
    builder.addCase(userAuthenticationReq.rejected, (state) => {
      delete state?.auth?.loading
    })

    // Handle login and signup request
    builder.addCase(userLoginReq.pending, (state) => {
      state.user.loading = true
    })
    builder.addCase(userLoginReq.fulfilled, (state, { payload }) => {
      delete state.user.loading
      state.user = payload || {}
    })
    builder.addCase(userLoginReq.rejected, (state) => {
      delete state.user.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default auth.reducer