import { handleDeletePropertiesReq } from "../../../redux/adminSlices/property.slice"
import { hideDialog, refresher } from "../../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import { green, red } from "@mui/material/colors"
import { useState } from "react"

import {
  Box, Button, CircularProgress, DialogActions,
  DialogContent, Divider, Fab, IconButton,
  Paper, Typography
} from "@mui/material"

function DeleteProperty() {
  const dispatch = useDispatch()
  const [success, setSuccess] = useState(false)
  const { data } = useSelector(state => state.otherSlice)
  const { property } = useSelector(state => state.propertySlice)

  // Close dialog
  const handleClose = () => {
    dispatch(hideDialog())
  }

  // Handle delete user
  const handleDelete = () => {
    dispatch(handleDeletePropertiesReq(data?.propId)
    ).unwrap().then(() => {
      setSuccess(true)
      setTimeout(() => {
        handleClose()
        dispatch(refresher())
      }, 500)
    })
  }

  return (
    <Paper id="DeleteUser">
      <DialogContent className="relative">
        <Box className="inline-block absolute top-[5px] right-[5px]">
          <IconButton
            size="small"
            onClick={handleClose}
          ><MuiIcons.ClearRounded sx={{ fontSize: "18px" }} />
          </IconButton>
        </Box>
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Box sx={{ m: 1, position: "relative", display: "inline-block" }}>
            <Fab
              sx={{
                cursor: "default",
                boxShadow: "none !important",
                color: success ? green[500] : red[400],
                bgcolor: success ? `${green[50]} !important` : `${red[50]} !important`,
                border: success ? `2px dashed ${green[400]}` : `2px dashed ${red[400]}`
              }}
              disableRipple
            >
              {!success ?
                <MuiIcons.PriorityHighRounded sx={{ fontSize: "30px" }} /> :
                <MuiIcons.CheckCircleRounded sx={{ fontSize: "30px" }} />
              }
            </Fab>
            {property?.loadingDelete && (
              <CircularProgress
                size={68}
                sx={{
                  top: -6,
                  left: -6,
                  zIndex: 1,
                  color: red[500],
                  position: "absolute",
                }}
              />
            )}
          </Box>
        </Box>
        <Typography
          sx={{
            mt: 1,
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: { xs: "18px", md: "24px" },
          }}
        >Are you sure, You want to delete the property?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            minWidth: "80px",
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "none",
            boxShadow: "none !important"
          }}
          size="small"
          color="error"
          variant="contained"
          onClick={handleClose}
        >Cancel
        </Button>
        <Button
          sx={{
            minWidth: "80px",
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "none",
            boxShadow: "none !important"
          }}
          size="small"
          color="success"
          variant="contained"
          onClick={handleDelete}
          disabled={property?.loadingAll}
        >Delete
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default DeleteProperty