import MuiIcons from "../../assets/js/muiIcons"
import { Input } from "@mui/material"
import { forwardRef, Fragment } from "react"

const SearchInput = forwardRef(({ onChangeFun }, ref) => {

  return (
    <Fragment>
      <Input
        sx={{
          p: "4px 8px",
          width: "100%",
          borderRadius: "6px",
          border: "1px solid #989898",
          ":after": { display: "none" },
          ":before": { display: "none" },

          "input": {
            p: "0",
            color: "#110e24",
            fontSize: "15px",
            fontFamily: "Poppins",
          },
        }}
        startAdornment={
          <MuiIcons.SearchRounded
            sx={{ fontSize: "18px", color: "#989898" }}
          />
        }
        type="text"
        inputRef={ref}
        onChange={onChangeFun || null}
      />
    </Fragment>
  )
})

export default SearchInput