import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material"
import MuiIcons from "../../assets/js/muiIcons"
import { useState } from "react"
import { Cookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

function Header({ sidebarActive, children }) {
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const handleClose = () => { setAnchorEl(null) }
  const handleClick = (event) => { setAnchorEl(event.currentTarget) }

  const handleLogout = () => {
    cookies.remove("adminToken", { path: "/" })
    dispatch({ type: "Logout", payload: "" })
    navigate("/admin/login")
  }

  return (
    <Box
      sx={{
        px: 2,
        gap: 1,
        py: 1.5,
        display: "flex",
        alignItems: "center",
        boxShadow: "0px 0px 10px 0px #00000024",
      }}
      component="header"
    >
      <Box
        sx={{
          gap: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          size="small"
          onClick={sidebarActive}
          sx={{
            display: { xs: "flex", laptop: "none" }
          }}
        ><MuiIcons.DragHandleRounded
            sx={{ fontSize: "20px", color: "#110e24" }}
          />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: "1" }} >
        {children}
      </Box>

      <IconButton
        size="small"
        onClick={handleClick}
      >
        <Avatar sx={{ width: 24, height: 24 }} />
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          ".MuiPaper-root": {
            boxShadow: "0px 0px 10px 0px #00000024"
          }
        }}
      >
        <MenuItem
          as={Link}
          to="/admin/profile"
          onClick={handleClose}
          sx={{ minWidth: "120px" }}
        >Profile
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{ minWidth: "120px" }}
        >Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default Header